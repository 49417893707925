.numContainer {
  position: relative;
  margin: 0 auto;
  text-align: center;
  padding-top: 80px;
  width: 500px;
}

.num {
  position: relative;
  display: inline-block;
  padding: 0 20px;
  background-image: var(--table-background);
  border-radius: 5px;
  color: white;
  font-size: 8rem;
  height: 200px;
  perspective: 1000px;
  transition: all 0.3s linear;
  margin-left: -6px;
  margin-right: -6px;
  -webkit-box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.4) inset;
  box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.4) inset;
  font-family: "Roboto Condensed", sans-serif;
  margin-right: 20px;
}

.space {
  display: inline-block;
}

.bit {
  height: 10px;
  width: 6px;
  border-radius: 25px;
  background-image: white;
  display: inline-block;
  margin-bottom: 39px;
}

hr {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  border: 1px solid black;
  border-left: 0 solid white;
  border-right: 0 solid white;
}

.day {
  display: inline-block;
  font-size: 2em;
  bottom: 0;
}

.button {
  margin-top: 20px;
  background-image: var(--table-background);
  border-radius: 5px;
  padding: 15px 20px;
  color: white;
  transition: all 0.6s ease;
  font-size: 1rem;
  cursor: pointer;
}

.button:hover {
  background-color: white;
  color: white;
  transform: scale(0.9);
}
