
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body{
  font-family: "Inter", sans-serif;
  /* background-color: #F5F5F5; */
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

input:-webkit-autofill {
  background-color: white !important; /* Your desired background */
  color: #333 !important;              /* Your desired text color */
  -webkit-box-shadow: 0 0 0px 1000px white inset !important; /* Removes blue highlight */
  transition: background-color 5000s ease-in-out 0s; /* Ensures smooth transitions */
}

/* //styleName: h3;
font-family: Inter;
font-size: 24px;
font-weight: 600;
line-height: 32px;
letter-spacing: -0.025em;
text-align: center; */
