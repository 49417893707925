
.resizable-handle {
  width: 5px;
  background-color: #ddd;
  cursor: ew-resize;
}

.main-content {
  overflow: auto;
}

.narrow .sidebar-item span {
  display: none;
}

.narrow .sidebar-item a img {
  margin-right: 0;
}
.sidebar-container {
  display: flex;
  height: 100vh;
}

.sidebar {
  overflow: hidden; /* Prevents scrolling in the sidebar */
  height: 100%;
}

.content-panel {
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevents the content panel from scrolling */
}

.content-area {
  flex-grow: 1;
  overflow-y: auto; /* Allows the content area to scroll */
  padding: 0px;
  background-color: #f8f9fa;
  min-height: 0; /* Important for the flexbox to allow scrolling */
}



 .sidebar-item.active a img {
  filter: brightness(0) invert(1);
}
.sidebar-item {
  width: 100%;
}

.sidebar-item a {
  text-decoration: none;
  color: inherit;
  display: block; /* Make it block level to span full width */
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box; /* Ensure padding is included within the width */
}

.sidebar-item a.active {
  background-color: #10B070;
  color: white;
}

.sidebar-item a:hover {
  background-color: #f4f4f5;
  color: black;
}

.sidebar-item a img {
  margin-right: 10px;
  transition: filter 0.3s;
}

.sidebar-item1 a.active {
  
  color: #10B070;
}
.sidebar-item1 a:hover {
 
  color: #10B070;
}
/* Default span style */
.sidebar-item1 a span {
  border-bottom: none;
  transition: border-bottom 0.3s;
}

/* Add border-bottom on hover */
.sidebar-item1 a:hover span {
  border-bottom: 1px solid #10B070;
}

/* Add border-bottom when the link is active */
.sidebar-item1 a.active span {
  border-bottom: 1px solid #10B070;
}
 .sidebar-item a.active img {
  filter: brightness(0) invert(1);
}

.sidebar-item1 a:hover img,
.sidebar-item1 a.active img {
  filter: invert(64%) sepia(50%) saturate(1300%) hue-rotate(90deg) brightness(65%) contrast(70%) !important;
}


.sidebar-item1 a img {
  margin-right: 10px;
  transition: filter 0.3s;
}



/* Sidebar.css */
.sidebar-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.sidebar {
  height: 100%;
  background-color: #ffffff;
  border-right: 1px solid #ddd;
  transition: width 0.3s;
}

.resizable-panel {
  overflow-y: auto;
}

.content-panel {
  flex-grow: 1;
  overflow-y: auto;
}

.content-area {
  padding: 16px;
  background-color: #f5f5f5;
}

/* Full width header */
/* header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: white;
  border-bottom: 1px solid #ddd;
} */
